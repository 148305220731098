import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Loader } from '../../components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import './public-layout.scss';
import setQueryParameters from "../../utils/setQueryParameters";

const PublicLayout = ({ children }) => {
    const { isLoading } = useSelector(state => state.loading)
    const { search } = useLocation();

    useEffect(() => {
        setQueryParameters(search);
    }, [])

    return (
        <>
            {isLoading && <Loader />}
            <main className="main-block">
                <div className="main-block__images-container">
                    <img
                        className="main-block__logo"
                        src="/images/logo-trek-shoes.cdr.png"
                        width="198"
                        height="40"
                    />
                    <img
                        className="main-block__logo"
                        src="/images/logo-avt-sport.png"
                        width="164"
                        height="68"
                    />
                </div>
                {children}
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </main>
        </>
    );
};

export default PublicLayout;